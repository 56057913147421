import { Stack } from "@mui/system";
import { useCallback, useState } from "react";
import { SingleInput } from "./SingleInput";

export const OTPInput = ({
	length,
	onChangeOTP,
	isNumberInput,
	autoFocus,
	disabled,
	error
}) => {
	const [activeInput, setActiveInput] = useState(0);
	const [otpValues, setOTPValues] = useState(Array(length).fill(""));

	const handleOtpChange = useCallback(
		(otp) => {
			const otpValue = otp.join("");
			onChangeOTP(otpValue);
		},
		[onChangeOTP]
	);

	const changeCodeAtFocus = useCallback(
		(str) => {
			const updatedOTPValues = [...otpValues];
			updatedOTPValues[activeInput] = str[0] || "";
			setOTPValues(updatedOTPValues);
			handleOtpChange(updatedOTPValues);
		},
		[activeInput, handleOtpChange, otpValues]
	);
	const getRightValue = useCallback(
		(str) => {
			let changedValue = str;

			if (!isNumberInput || !changedValue) {
				return changedValue;
			}

			return Number(changedValue) >= 0 ? changedValue : "";
		},
		[isNumberInput]
	);

	const focusInput = useCallback(
		(inputIndex) => {
			const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
			setActiveInput(selectedIndex);
		},
		[length]
	);

	const focusPrevInput = useCallback(() => {
		focusInput(activeInput - 1);
	}, [activeInput, focusInput]);

	const focusNextInput = useCallback(() => {
		focusInput(activeInput + 1);
		console.log("yes");
	}, [activeInput, focusInput]);

	const handleOnFocus = useCallback(
		(index) => () => {
			focusInput(index);
		},
		[focusInput]
	);

	const handleOnChange = useCallback(
		(e) => {
			const val = getRightValue(e.currentTarget.value);
			if (!val) {
				e.preventDefault();
				return;
			}
			changeCodeAtFocus(val);
			focusNextInput();
		},
		[changeCodeAtFocus, focusNextInput, getRightValue]
	);

	const onBlur = useCallback(() => {
		setActiveInput(-1);
	}, []);

	const handleOnKeyDown = useCallback(
		(e) => {
			const pressedKey = e.key;

			switch (pressedKey) {
				case "Backspace":
				case "Delete": {
					e.preventDefault();
					if (otpValues[activeInput]) {
						changeCodeAtFocus("");
					} else {
						focusPrevInput();
					}
					break;
				}
				case "ArrowLeft": { 
					e.preventDefault();
					focusPrevInput();
					break;
				}
				case "ArrowRight": {
					e.preventDefault();
					focusNextInput();
					break;
				}
				default: {
					if (pressedKey.match(/^[^a-zA-Z0-9]$/)) {
						e.preventDefault();
					}

					break;
				}
			}
		},
		[
			activeInput,
			changeCodeAtFocus,
			focusNextInput,
			focusPrevInput,
			otpValues,
		]
	);

	const handleOnPaste = useCallback(
		(e) => {
			e.preventDefault();
			const pastedData = e.clipboardData
				.getData("text/plain")
				.trim()
				.slice(0, length - activeInput)
				.split("");
			if (pastedData) {
				let nextFocusIndex = 0;
				const updatedOTPValues = [...otpValues];
				updatedOTPValues.forEach((val, index) => {
					if (index >= activeInput) {
						const changedValue = getRightValue(
							pastedData.shift() || val
						);
						if (changedValue) {
							updatedOTPValues[index] = changedValue;
							nextFocusIndex = index;
						}
					}
				});
				setOTPValues(updatedOTPValues);
				setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
			}
		},
		[activeInput, getRightValue, length, otpValues]
	);
	return (
		<Stack
			direction={"row"}
			spacing={1}
			justifyContent="center"
			alignItems="center"
		>
			{Array(length)
				.fill("")
				.map((_, index) => {
					return (
						<SingleInput
						error={error}
							key={`SingleInput-${index}`}
							type="tel"
							focus={activeInput === index}
							value={otpValues && otpValues[index]}
							autoFocus={autoFocus}
							onFocus={handleOnFocus(index)}
							onChange={handleOnChange}
							onKeyDown={handleOnKeyDown}
							onBlur={onBlur}
							onPaste={handleOnPaste}
							disabled={disabled}
						/>
					);
				})}
		</Stack>
	);
};
