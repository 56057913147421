import { TextField } from "@mui/material";
import { useLayoutEffect, useRef } from "react";
import { usePrevious } from "./previousHook";

export const SingleInput=(props)=>{
    const { focus, autoFocus, ...rest } = props;
  const inputRef = useRef(null);
  const prevFocus = usePrevious(!!focus);
  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return <TextField  inputProps={{sx:{textAlign:"center"}}} inputRef={inputRef} {...rest} />;
}
