import { Box, Drawer, useMediaQuery, useTheme, Skeleton } from "@mui/material";
import { Fragment, useRef, useState } from "react";
import { Paper, Slide } from "@mui/material";
import { PhoneScreen } from "./PhoneScreen";
import { OTPInputScreen } from "./OTPInputScreen";
import { NameScreen } from "./NameScreen";
import { InstagramScreen } from "./InstagramScreen";
import { ToastContainer } from "react-toastify";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import OtpScreenSkeleton from "../Skeletons/Screens/OtpScreenSkeleton";
import NameScreenSkeleton from "../Skeletons/Screens/NameScreenSkeleton";
import InstaSkeleton from "../Skeletons/Screens/InstaSkeleton";

export const AuthDrawer = ({ setOpen, open }) => {
	const [screen, setScreen] = useState({
		instaScreen: false,
		otpScreen: false,
		phoneScreen: true,
	});
	const [isLoading, setIsLoading] = useState(false);

	const [user, setUser] = useState({});
	const handleClose = () => {
		setOpen(false);
		setUser({});
		setIsLoading(false)
		setScreen({
			instaScreen: false,
			otpScreen: false,
			phoneScreen: true,
		});
	};
	const handleScreen = (data) => {
		let oldstate = {
			nameScreen: false,
			instaScreen: false,
			otpScreen: false,
			phoneScreen: false,
		};
		
		setScreen({ ...oldstate, ...data });
		setIsLoading(false)
	};

	const { phoneScreen, instaScreen, nameScreen, otpScreen } = screen;
	const toggleDrawer = (value) => {
		setOpen(value);
	};

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	// const [open, setOpen] = useState(true);
	const containerRef = useRef(null);
	
	let mobileCss=!isMobile?{
		width:480,left:"50%",transform: "translate(-50%)"
	}:{width:"100%"}

	return (
		<div>
			<Fragment>
				
				<Drawer
				    sx={{
						zIndex:100001,
						...mobileCss
					}}
					anchor={"bottom"}
					open={open}
					slotProps={{backdrop:{style:{ backgroundColor: 'rgba(0, 0, 0, 0.7)'}}}}
					onClose={() => toggleDrawer(false)}
					PaperProps={{
						square: false,
						sx: { borderRadius: "24px 24px 0px 0px",},
					}}
					// PaperProps={sx={borderRadius: "24px 24px 0px 0px"}}
				>
					
					
					{/* Same as */}
					<ToastContainer />
					<Paper sx={{ padding: 3.75 }}>
					{/* {isLoading&&<LoadingSpinner/>} */}
						<Slide
							in={phoneScreen}
							direction={otpScreen?"right":"up"}
							container={containerRef.current}
							mountOnEnter
							unmountOnExit
						>
							<Box>
								<PhoneScreen
									user={user}
									setIsLoading={setIsLoading}
									setUser={setUser}
									setOpen={setOpen}
									handleScreen={handleScreen}
									handleClose={handleClose}
								/>
							</Box>
							
						</Slide>
						<Slide
							in={otpScreen}
							direction="left"
							container={containerRef.current}
							mountOnEnter
							unmountOnExit
						>
							{isLoading?<OtpScreenSkeleton/>:
							<Box>
								<OTPInputScreen
									user={user}
									setUser={setUser}
									setIsLoading={setIsLoading}
									handleScreen={handleScreen}
									handleClose={handleClose}
								/>
							</Box>
}
						</Slide>
						<Slide
							in={nameScreen}
							direction="right"
							container={containerRef.current}
							mountOnEnter
							unmountOnExit
						>
							
							 <Box>
							 {isLoading?<NameScreenSkeleton></NameScreenSkeleton>:
								<NameScreen
									user={user}
									setUser={setUser}
									setIsLoading={setIsLoading}
									handleScreen={handleScreen}
									handleClose={handleClose}
								/>}
							</Box> 
						</Slide>
						<Slide
							in={instaScreen}
							direction="right"
							container={containerRef.current}
							mountOnEnter
							unmountOnExit
						>
							
							<Box sx={{ width: "100%" }}>
							{isLoading?<InstaSkeleton></InstaSkeleton>:
								<InstagramScreen
									user={user}
									setUser={setUser}
									setIsLoading={setIsLoading}
									handleScreen={handleScreen}
									handleClose={handleClose}
								/>}
							</Box>

						</Slide>
					</Paper>
				</Drawer>
			</Fragment>
		</div>
	);
};
