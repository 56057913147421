import { NextSeo } from "next-seo";

const GeneralHead = ({ description, ogUrl, ogImage, ogTitle }) => {
    return  <NextSeo
				title={ogTitle}
				description={description}
				canonical="https://app.grape.town"
				openGraph={{
					url: ogUrl,
					title: ogTitle,
					description: description,
					images: [
						// {
						// 	url: "https://www.example.com/og-image01.jpg",
						// 	width: 800,
						// 	height: 600,
						// 	alt: "Og Image Alt",
						// 	type: "image/jpeg",
						// },
						// {
						// 	url: "https://www.example.com/og-image02.jpg",
						// 	width: 900,
						// 	height: 800,
						// 	alt: "Og Image Alt Second",
						// 	type: "image/jpeg",
						// },
						{ url: ogImage },
						// { url: "https://www.example.com/og-image04.jpg" },
					],
					site_name: "Grape.town",
				}}
				// twitter={{
				// 	handle: "@handle",
				// 	site: "@site",
				// 	cardType: "summary_large_image",
				// }}
			/>
      
    
  };
  
  export default GeneralHead;