
import { Button, IconButton, Skeleton, TextField, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import image from "assets/images/authentication/City girl-bro 2.png";

function NameScreenSkeleton() {
  return (
    <Box>
    <Stack
        spacing={2}
        pt={2}
        direction="row"
        justifyContent="space-between"
    >
        <Box>
           
        </Box>
        <Box pt={1}>
            <img src={image} alt="phone" />
        </Box>
        <Box>
         
        </Box>
    </Stack>
    <Box mt={2} sx={{ textAlign: "center" }}>
        <Typography variant="h1">You’re almost done!</Typography>
     
    </Box>

   
        <Box my={3}>
        <Skeleton variant="rounded" animation="wave" height={56} />
        </Box>
       
        <Button
            size={"large"}
            type="submit"
            variant="contained"
            fullWidth
            disabled
        >
            Complete Setup
        </Button>
    
</Box>
  )
}

export default NameScreenSkeleton
