import {
	Avatar,
	AvatarGroup,
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	IconButton,
	InputAdornment,
	Link,
	Stack,
	TextField,
	Typography,
} from "@mui/material";

import phone from "assets/images/authentication/Niche service marketplace-bro 1.png";
import close_button from "assets/icon/close_button.png";
import { useForm } from "react-hook-form";
// import { validatePhone } from "../../apis/auth";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";

import {
	getAuth,
	signInWithPhoneNumber,
	linkWithCredential,
	PhoneAuthProvider,
	RecaptchaVerifier,
} from "firebase/auth";
import { sendOTP } from "../../service/auth";
import { useState, useEffect } from "react";

export const PhoneScreen = ({
	handleClose,
	user,
	setUser,
	handleScreen,
	setIsLoading,
}) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const notify = (m) => {
		toast.error(m, {
			position: "top-center",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
		});
	};

	const handleRegistration = async (data) => {
		setIsLoading(true);

		const phoneNumber = parsePhoneNumber(`+91${data.phone}`);
		let formdata = { phone: phoneNumber.number };
		// console.log({
		// 	...user,
		// 	phone: phoneNumber.nationalNumber,
		// 	phone_with_code: formdata.phone,
		// });
		setUser({
			...user,
			phone_with_code: formdata.phone,
			phone: phoneNumber.nationalNumber,
		});
		let res = await sendOTP(formdata);
		if (res.status) {
			setIsLoading(false);
			handleScreen({
				otpScreen: true,
			});
		} else {
			setIsLoading(false);
			notify("Something went wrong While sending OTP");
		}
	};

	return (
		<Box>
			<Box id="recaptcha-container"></Box>
			<Stack
				spacing={2}
				pt={2}
				direction="row"
				justifyContent="space-between"
			>
				<Box></Box>
				<Box pt={1}>
					<img src={phone} alt="phone" />
				</Box>
				<Box>
					<IconButton
						onClick={handleClose}
						// size="small"
						sx={{
							padding: 0,
							justifySelf: "center",
						}}
					>
						<img src={close_button} alt="<"></img>
					</IconButton>
				</Box>
			</Stack>
			<Box mt={2} sx={{ textAlign: "center" }}>
				<Typography variant="h1">
					Discover & curate fashion with the Grape community 💜
				</Typography>
			</Box>
			<Box mt={3.75} sx={{ textAlign: "center" }}>
				<AvatarGroup
					max={6}
					spacing={12}
					sx={{
						justifyContent: "center",
						border: "1px solid #FFFFFF",
					}}
				>
					<Avatar
						sx={{
							width: 32,
							height: 32,
							border: "1px solid #FFFFFF",
						}}
						alt="Remy Sharp"
						src="/static/images/avatar/1.jpg"
					/>
					<Avatar
						sx={{
							width: 32,
							height: 32,
							border: "1px solid #FFFFFF",
						}}
						alt="Travis Howard"
						src="/static/images/avatar/2.jpg"
					/>
					<Avatar
						sx={{
							width: 32,
							height: 32,
							border: "1px solid #FFFFFF",
						}}
						alt="Cindy Baker"
						src="/static/images/avatar/3.jpg"
					/>
					<Avatar
						sx={{
							width: 32,
							height: 32,
							border: "1px solid #FFFFFF",
						}}
						alt="Agnes Walker"
						src="/static/images/avatar/4.jpg"
					/>
					<Avatar
						sx={{
							width: 32,
							height: 32,
							border: "1px solid #FFFFFF",
						}}
						alt="Trevor Henderson"
						src="/static/images/avatar/5.jpg"
					/>
					<Avatar
						sx={{
							width: 32,
							height: 32,
							border: "1px solid #FFFFFF",
						}}
						alt="Trevor Henderson"
						src="/static/images/avatar/5.jpg"
					/>
				</AvatarGroup>
				<Typography py={1} variant="body2">
					Join 11,486 people like you
				</Typography>
			</Box>
			<form onSubmit={handleSubmit(handleRegistration)}>
				<Box my={2}>
					<TextField
						{...register("phone", {
							required: "Please enter phone number",
							validate: (value) =>
								isValidPhoneNumber(`+91${value}`) ||
								"Invalid Phone number!",
						})}
						InputProps={{
							maxLength: 10,
							startAdornment: (
								<InputAdornment position="start">
									+91
								</InputAdornment>
							),
						}}
						type="text"
						value={user?.phone ? user.phone : ""}
						fullWidth
						error={Boolean(errors.phone)}
						helperText={errors.phone?.message}
						color="secondary"
						label="Phone no."
						autoComplete="off"
						onChange={(e) => {
							setUser({ ...user, phone: e.target.value });
						}}
					/>
				</Box>
				<Box mt={2} mb={3}>
					<FormControl error={Boolean(errors.agree_terms)}>
						<FormGroup>
							<FormControlLabel
								required
								control={
									<Checkbox
										defaultChecked
										{...register("agree_terms", {
											required:
												"You must agree the terms of service and privacy policy",
										})}
										name="agree_terms"
									/>
								}
								label={
									<Typography variant="body2" component={"p"}>
										By continuing, I agree to the{" "}
										<Link
											underline="none"
											href="/terms_conditions"
											target="_blank"
										>
											Terms of Use
										</Link>{" "}
										&{" "}
										<Link
											underline="none"
											href="/privacy-policy"
											target="_blank"
										>
											Privacy Policy
										</Link>
									</Typography>
								}
							/>
						</FormGroup>
						<FormHelperText>
							{errors.agree_terms?.message}
						</FormHelperText>
					</FormControl>
				</Box>
				<Button
					size={"large"}
					type="submit"
					variant="contained"
					fullWidth
				>
					Sign in
				</Button>
			</form>
		</Box>
	);
};
