import { Button, IconButton, TextField, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import image from "assets/images/authentication/City girl-bro 2.png";
import { useState } from "react";
// import { createUser, verifyPhone } from "../../apis/auth";
import close_button from "assets/icon/close_button.png";
import { getUser } from "../../utility/UserToken";
// import { saveToken } from "../../util/userToken";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createUser, updateUser } from "../../service/auth";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getMessagingToken } from "../../lib/firebase";
import { getSession, signIn } from "next-auth/react";

export const NameScreen = ({
	setUser,
	user,
	handleScreen,
	handleClose,
	setIsLoading,
}) => {
	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const [otp, setOtp] = useState();
	const notify = (m) => {
		toast.error(m, {
			position: "top-center",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
		});
	};

	const handleRegistration = async (data) => {
		setIsLoading(true);
		data.firstname = data.name.split(" ")[0];
		data.lastname =
			data.name.split(" ").length > 1 ? data.name.split(" ")[1] : " ";
		
		const auth = getAuth();
		let currentUser = auth.currentUser;
		data["uid"] = currentUser.uid;
		setUser({ ...user, ...data });

		let createdata = {
			firstname:data.firstname,
			lastname:data.lastname,
			...user,
			phone: user.phone_with_code,
			username: user.phone_with_code,
			uid: currentUser.uid,
			type:"new_user",
			redirect: false,
		};
		signIn("credentials", createdata).then(async (res) => {
			
			if(res.error!=null){
				setIsLoading(false)
				notify(
					"Something went wrong While creating account"
				);
				return
			}
			const session = await getSession();
			setUser({ ...user, id: session.user.id });
			getMessagingToken(session);
			if (session.custom_token !== null) {
				signInWithCustomToken(auth, session.custom_token)
					.then((res) => {
						setIsLoading(false);
					})
					.catch((error) => {
						console.log(error);
						notify(
							"Something went wrong While signing in with firebase"
						);
						setIsLoading(false);
					});
			}
			handleScreen({
				instaScreen: true,
			});
			
			setIsLoading(false);
		});
		// createUser(createdata)
		// 	.then((res) => {
		// 		let { data } = res;

		// 		if (data.custom_token != null) {
		// 			signInWithCustomToken(auth, data.custom_token)
		// 				.then((res) => {})
		// 				.catch((error) => {
		// 					console.log(error);
		// 					setIsLoading(false);
		// 					notify(
		// 						"Something went wrong While signing in with firebase in create flow"
		// 					);
		// 				});
		// 		}
		// 		setUser({ ...user, id: data.user.id });
		// 		getMessagingToken()
		// 		handleScreen({
		// 			instaScreen: true,
		// 		});
		// 		setIsLoading(false);
		// 		// handleClose();
			
		// 	})
		// 	.catch((error) => {
		// 		notify("Unable to create account! please try again");
		// 		setIsLoading(false);
		// 	});
		
		

		// updateUser(data, user.id)
		// 	.then((data) => {
		// 		handleScreen({
		// 			instaScreen: true,
		// 		});
		// 		setIsLoading(false);
		// 		// handleClose();
		// 	})
		// 	.catch((error) => {
		// 		notify("Something went wrong");
		// 		setIsLoading(false);
		// 	});
	};
	return (
		<Box>
			<Stack
				spacing={2}
				pt={2}
				direction="row"
				justifyContent="space-between"
			>
				<Box>
					{/* <IconButton
						onClick={() => {
							setNameScreen(false);
							setOtpScreen(true);
						}}
						sx={{ padding: 0 }}
					>
						<img src={chevron_left} alt="<"></img>
					</IconButton> */}
				</Box>
				<Box pt={1}>
					<img src={image} alt="phone" />
				</Box>
				<Box>
					{/* <IconButton
						onClick={handleClose}
						// size="small"
						sx={{
							padding: 0,
							justifySelf: "center",
						}}
					>
						<img src={close_button} alt="<"></img>
					</IconButton> */}
				</Box>
			</Stack>
			<Box mt={2} sx={{ textAlign: "center" }}>
				<Typography variant="h1">You’re almost done!</Typography>
				{/* <Typography variant="body2" paddingTop={2.5}>
					Enter code sent to your phone number
				</Typography> */}
			</Box>

			<form onSubmit={handleSubmit(handleRegistration)}>
				<Box my={3}>
					<TextField
						{...register("name", {
							required: "Enter name",
							// pattern: {
							// 	value: /^[a-zA-Z]+ [a-zA-Z]+$/,
							// 	message:
							// 		"Please enter first name and last name",
							// },
						})}
						value={user?.name ? user.name : ""}
						fullWidth
						onChange={(e) => {
							setUser({ ...user, name: e.target.value });
						}}
						error={Boolean(errors.name)}
						helperText={errors.name?.message}
						color="secondary"
						label="Your Name"
					/>
				</Box>
				{/* <Box my={3}>
					<TextField
						{...register("lastname", {
							required: "Enter last name",
						})}
						value={user?.lastname ? user.lastname : ""}
						onChange={(e) => {
							setUser({ ...user, lastname: e.target.value });
						}}
						fullWidth
						error={Boolean(errors.lastname)}
						helperText={errors.lastname?.message}
						color="secondary"
						label="Last Name"
					/>
				</Box> */}
				{/* <Box my={3}>
					<TextField
						{...register("username", {
							required: "Enter username",
						})}
						value={user?.username ? user.username : ""}
						fullWidth
						onChange={(e) => {
							setUser({ ...user, username: e.target.value });
						}}
						error={Boolean(errors.username)}
						helperText={errors.username?.message}
						color="secondary"
						label="Username"
					/>
				</Box> */}
				<Button
					size={"large"}
					type="submit"
					variant="contained"
					fullWidth
				>
					Complete Setup
				</Button>
			</form>
		</Box>
	);
};
