
import React from "react";
import otpimage from "assets/images/authentication/Enter OTP-bro 1.png";
import { Button, Skeleton, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";

function OtpScreenSkeleton() {
  return (    
    <Box>
      <Stack spacing={2} pt={2} direction="row" justifyContent="space-between">
        <Box></Box>
        <Box pt={1}>
          <img src={otpimage} alt="phone" />
        </Box>
        <Box></Box>
      </Stack>
      <Box mt={2} sx={{ textAlign: "center" }}>
        <Typography variant="h1">Verifying OTP</Typography>
        <Typography variant="body2" paddingTop={2.5}>
          Enter code sent to your phone number
        </Typography>
      </Box>
      <Box my={3} justifyItems={"center"}>
        <Stack direction="row" spacing={2} justifyContent={"space-evenly"}>
          <Skeleton variant="rectangular" width={63.3} height={56} />
          <Skeleton variant="rectangular" width={63.3} height={56} />
          <Skeleton variant="rectangular" width={63.3} height={56} />
          <Skeleton variant="rectangular" width={63.3} height={56} />
          <Skeleton variant="rectangular" width={63.3} height={56} />
          <Skeleton variant="rectangular" width={63.3} height={56} />
        </Stack>

        <Stack
          direction={"row"}
          justifyContent="center"
          alignItems={"center"}
          pt={2.7}
        >
          <Typography variant="body2">Didn’t receive OTP? </Typography>
          <Button
            size="small"
            component="span"
            // sx={{ padding: 0 }}
            variant="text"
            disabled
          >
            Resend
          </Button>
        </Stack>
      </Box>
      <Button
        size={"large"}
        type="submit"
        variant="contained"
        fullWidth
        disabled
      >
        Next
      </Button>
    </Box>
  );
}
export default OtpScreenSkeleton;
