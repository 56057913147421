import { Button, IconButton, TextField, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import image from "assets/images/authentication/Subscriber-bro 1.png";
import { useState } from "react";
// import { createUser, updateUser, verifyPhone } from "../../apis/auth";
import close_button from "assets/icon/close_button.png";
import { updateUser } from "../../service/auth";
import { useSession } from "next-auth/react";
// import { saveToken } from "../../util/userToken";

export const InstagramScreen = ({
	setOtpScreen,
	setNameScreen,
	setUser,
	user,
	setIsLoading,
	handleScreen,
	handleClose,
}) => {
	const { data: session, status } = useSession();
	const {
		register,

		handleSubmit,
		formState: { errors },
	} = useForm();
	const notify = (m) => {
		toast.error(m, {
			position: "top-center",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
		});
	};
	const handleRegistration = async (data) => {
		// console.log(data);
		// setUser({ ...user, data });
		// data.phone = user.phone;
		setIsLoading(true)
        console.log(data);
		updateUser(data, user.id,session).then((data) => {
			
			handleClose();
		})
		.catch((error) => {
			setIsLoading(false)
			notify("Something went wrong");
		});
		// let res = await updateUser(data,user.id);
        // // saveToken(res.data)
		// handleClose()

		//         result.confirm(data.otp)
		//         .then( result => {

		//          console.log(result)

		// })
		// .catch( error => console.log(error, "Incorrect code entered?"));
		// setPhoneScreen(false);
		// setOtpScreen(true);
	};
	return (
		<Box>
			<Stack
				spacing={2}
				pt={2}
				direction="row"
				justifyContent="space-between"
			>
				<Box>
					{/* <IconButton
						onClick={() => {
							setNameScreen(false);
							setOtpScreen(true);
						}}
						sx={{ padding: 0 }}
					>
						<img src={chevron_left} alt="<"></img>
					</IconButton> */}
				</Box>
				<Box pt={1}>
					<img src={image} alt="phone" />
				</Box>
				<Box>
					<IconButton
						onClick={handleClose}
						// size="small"
						sx={{
							padding: 0,
							justifySelf: "center",
						}}
					>
						<img src={close_button} alt="<"></img>
					</IconButton>
				</Box>
			</Stack>
			<Box mt={2} sx={{ textAlign: "center" }}>
				<Typography variant="h1">Get notified</Typography>
				<Typography variant="body2" paddingTop={2.5}>
				Be in the spotlight: Connect with us and get featured with your curations!
				</Typography>
			</Box>

			<form onSubmit={handleSubmit(handleRegistration)}>
				<Box my={3}>
					<TextField
						{...register("insta_handle", {
							required: "Enter insta id",
							pattern: {
								value: /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/,
								message:
									"please type insta username",
							},
							
						})}
						value={user?.insta_handle ? user.insta_handle : ""}
						fullWidth
						onChange={(e) => {
							setUser({ ...user, insta_handle: e.target.value });
						}}
						error={Boolean(errors.insta_handle)}
						helperText={errors.insta_handle?.message}
						color="secondary"
						label="Your Instagram handle"
					/>
				</Box>
				<Stack direction={"row"}> 
				<Button
					variant="text"
					fullWidth
					onClick={handleClose}
				>
					Skip, I’ll do it later
				</Button>
				<Button
					size={"large"}
					type="submit"
					variant="contained"
					fullWidth
				>
					All Done!
				</Button>
				</Stack>
			</form>
		</Box>
	);
};
