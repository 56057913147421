import {
	Button,
	FormControl,
	FormHelperText,
	IconButton,
	Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";

import chevron_left from "assets/icon/back_button.png";
import { Controller, useForm } from "react-hook-form";
import otpimage from "assets/images/authentication/Enter OTP-bro 1.png";
import { useState } from "react";

import { getAuth, signInWithCustomToken } from "firebase/auth";
import { OTPInput } from "./OTPInput/OTPInput";
import { getSession, signIn } from "next-auth/react";

import { createUser, getTokenByPhone, VerifyOTP } from "../../service/auth";

import { ToastContainer, toast } from "react-toastify";
import { getMessagingToken } from "../../lib/firebase";

export const OTPInputScreen = ({
	user,
	setUser,
	handleClose,
	handleScreen,
	setIsLoading,
}) => {
	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
	} = useForm();
	// setIsLoading(false);
	let handleLocalScreen = () => {
		handleScreen({
			nameScreen: false,
			instaScreen: false,
			otpScreen: false,
			phoneScreen: true,
		});
	};
	const [otp, setOtp] = useState();
	const notify = (m) => {
		toast.error(m, {
			position: "top-center",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
		});
	};

	const handleRegistration = async (data) => {
		setIsLoading(true);
		setUser({ ...user, otp: data.otp });
		data.phone = user.phone_with_code;
		const auth = getAuth();
		let currentUser = auth.currentUser;
		data["uid"] = currentUser.uid;
		let res = await VerifyOTP(data);

		if (res.validated) {
			if (res.sign_in) {
				signIn("credentials", {
					// redirect: '/',
					phone: data.phone,
					otp: data.otp,
					uid: currentUser.uid,
					redirect: false,
					type:""
				}).then(async (res) => {
					if(res.error!=null){
						notify(
							"Something went wrong While login account"
						);
						setIsLoading(false)
						return
					}
					const session = await getSession();
					setUser({ ...user, id: session.user.id });
					console.log(session);
					// getMessagingToken(session);
					if (session.custom_token !== null) {
						signInWithCustomToken(auth, session.custom_token)
							.then((res) => {
								setIsLoading(false);
							})
							.catch((error) => {
								console.log(error);
								notify(
									"Something went wrong While signing in with firebase"
								);
								setIsLoading(false);
							});
					}
					handleClose();
					setIsLoading(false);
				});

				
			} else {
				
				setIsLoading(false);

				handleScreen({
					nameScreen: true,
				});
			}
		} else {
			notify("Unable to verify your otp, try again");
			setIsLoading(false);
		}
	};

	return (
		<Box>
			<div id="recaptacha-container"></div>
			<Stack
				spacing={2}
				pt={2}
				direction="row"
				justifyContent="space-between"
			>
				<Box>
					<IconButton onClick={handleLocalScreen} sx={{ padding: 0 }}>
						<img src={chevron_left} alt="<"></img>
					</IconButton>
				</Box>
				<Box pt={1}>
					<img src={otpimage} alt="phone" />
				</Box>
				<Box></Box>
			</Stack>
			<Box mt={2} sx={{ textAlign: "center" }}>
				<Typography variant="h1">Verify OTP</Typography>
				<Typography variant="body2" paddingTop={2.5}>
					Enter code sent to your phone number
				</Typography>
			</Box>

			<form onSubmit={handleSubmit(handleRegistration)}>
				<Box my={3} justifyItems={"center"}>
					<FormControl error={Boolean(errors.otp)} fullWidth>
						<Controller
							control={control}
							name="otp"
							value={otp}
							onChange={({ newValue }) => setOtp(newValue)}
							rules={{ required: "Please enter Otp" }}
							render={({
								field: { onChange, onBlur, value, ref },
							}) => (
								<OTPInput
									length={6}
									autoFocus
									error={Boolean(errors.otp)}
									isNumberInput={true}
									onChangeOTP={(otp) => {
										onChange(otp);
									}}
								/>
							)}
						/>
						{errors.otp && (
							<FormHelperText sx={{ textAlign: "center" }}>
								{errors.otp.message}
							</FormHelperText>
						)}
					</FormControl>

					<Stack
						direction={"row"}
						justifyContent="center"
						alignItems={"center"}
						pt={2.7}
					>
						<Typography variant="body2">
							Didn’t receive OTP?{" "}
						</Typography>
						<Button
							size="small"
							component="span"
							// sx={{ padding: 0 }}
							onClick={handleLocalScreen}
							variant="text"
						>
							Resend
						</Button>
					</Stack>
				</Box>
				<Button
					size={"large"}
					type="submit"
					variant="contained"
					fullWidth
				>
					Next
				</Button>
			</form>
		</Box>
	);
};
