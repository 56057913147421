import React from 'react'
import { Button, IconButton, Skeleton, TextField, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import image from "assets/images/authentication/Subscriber-bro 1.png";
import close_button from "assets/icon/close_button.png";



function InstaSkeleton() {
  return (
    <Box>
    <Stack
        spacing={2}
        pt={2}
        direction="row"
        justifyContent="space-between"
    >
        <Box>
           
        </Box>
        <Box pt={1}>
            <img src={image} alt="phone" />
        </Box>
        <Box>
            <IconButton
                // onClick={handleClose}
                // size="small"
                sx={{
                    padding: 0,
                    justifySelf: "center",
                }}
            >
                <img src={close_button} alt="<"></img>
            </IconButton>
        </Box>
    </Stack>
    <Box mt={2} sx={{ textAlign: "center" }}>
        <Typography variant="h1">Get notified</Typography>
        <Typography variant="body2" paddingTop={2.5}>
        Be in the spotlight: Connect with us and get featured with your curations!
        </Typography>
    </Box>

 
        <Box my={3}>
        <Skeleton variant="rounded" animation="wave"  height={56} />
        </Box>
        <Stack direction={"row"}> 
        <Button
            variant="text"
            fullWidth
           disabled
        >
            Skip, I’ll do it later
        </Button>
        <Button
            size={"large"}
            type="submit"
            variant="contained"
            fullWidth
            disabled
        >
            All Done!
        </Button>
        </Stack>
   
</Box>
  )
}

export default InstaSkeleton
